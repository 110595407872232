/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import "./src/assets/vendor/aos/aos.css";
import "./src/assets/vendor/bootstrap/css/bootstrap.min.css";
import "./src/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./src/assets/vendor/boxicons/css/boxicons.min.css";
import "./src/assets/vendor/glightbox/css/glightbox.min.css";
import "./src/assets/vendor/swiper/swiper-bundle.min.css";
import "./src/assets/css/style.css";

import "./src/assets/vendor/bootstrap/js/bootstrap.bundle.min.js";

const $ = require('jquery');
window.$ = $;
window.jQuery = $;

const purecounter = require("./src/assets/vendor/purecounter/purecounter.js");
const AOS = require("./src/assets/vendor/aos/aos.js");
const GLightbox = require("./src/assets/vendor/glightbox/js/glightbox.min.js");
const Isotope = require("./src/assets/vendor/isotope-layout/isotope.pkgd.min.js");
const Swiper = require("./src/assets/vendor/swiper/swiper-bundle.min.js");
const Typed = require("./src/assets/vendor/typed/typed.min.js");

window.PureCounter = purecounter;
window.AOS = AOS;
window.GLightbox = GLightbox;
window.Isotope = Isotope;
window.Swiper = Swiper;
window.Typed = Typed;